.ending {
    background-color: #fafafa;
    margin: 0 1vw 5vh 1vw;
    padding: 5vh 5vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.ending__icons {
    display: flex;
    flex-direction: row;
}
.icon {
    margin: 5vh 1vw;
    color: black;
}