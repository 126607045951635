.projects {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.projects__list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 5vh 5vw;
}

.projects__container {
    /* padding: 5vh 5vw; */
    margin: 1vh 0.5vw;
    height: 250px;
    background-color: black;
    text-align: center;
    border-radius: 1rem;
    border: 1px;
    position: relative;
    inline-size: min-content;
}
.projects__container__img {
    border-radius: 1rem;
    opacity: 0.5;
    border: 5px solid black;
}


.projects__container .projects__container__text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: whitesmoke;
    font-size: 45px;
    visibility: visible;
    margin: 0;
    z-index: 1;
    width: 80%;
}

.projects__container:hover .projects__container__text {
    visibility: hidden;
}

.projects__container .projects__container__description {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: whitesmoke;
    font-size: 25px;
    visibility: hidden;
    z-index: 2;
    width: 80%;
    
}

.projects__container:hover .projects__container__description {
    visibility: visible;
}